<template>
  <Fieldset
    :legend="rule.name"
    v-for="(rule, index) in RuleListArray"
    :key="index"
    style="margin-top: 10px; margin-bottom: 25px"
  >
    <div
      v-for="chip in chips(index)"
      :key="chip.key"
      id="chipstart"
      style="display: inline-flex; padding: 0px"
    >
      <Modal modal-transition="move-up" modalClass="codeModal">
        <template #activator="props">
          <Button
            :class="chip.bclass"
            :severity="chip.severity"
            :label="
              chip.type == 'Event'
                ? chip.script + '  [' + chip.order + ']'
                : chip.script
            "
            :icon="chip.icon"
            v-tooltip.right.mouseover="
              '<b>' +
                chip.ext.toUpperCase() +
                '</b><br/>' +
                beautify(chip.settings)
            "
            v-bind="props"
          />
        </template>
        <template #default="{}">
          <span class="modalHeader">{{ chip.ext.toUpperCase() }}</span
          ><br />
          <Code v-if="chip.settings" v-bind:code="chip.settings" />
        </template>
      </Modal>
    </div>
  </Fieldset>
</template>
<script>
import { Modal } from "vue-neat-modal";
import Code from "./Code";
import { js as beautify } from "js-beautify";
export default {
  name: "Rules",
  props: ["RuleListArray"],
  components: { Code, Modal },
  methods: {
    beautify(cd) {
      return (
        '<pre class="code-tips">' +
        beautify(JSON.stringify(cd).replace(/(\\")/g, '"')) +
        "</pre>"
      );
    },
    chips(i) {
      let events = this.RuleListArray[i].events;
      let actions = this.RuleListArray[i].actions;
      let conditions = this.RuleListArray[i].conditions;
      let chipData = [];
      events.forEach((ev, i) => {
        let path = ev.modulePath;
        let split = path.split("/");
        let ext = split[0];
        let script = split[split.length - 1].replace(".js", "");
        let settings = ev.settings;
        let order = ev.ruleOrder;
        chipData.push({
          ext,
          script,
          settings,
          order,
          icon: "pi pi-check",
          type: "Event",
          key: "event-" + i,
          severity: "info",
          bclass: "p-button-primary"
        });
      });
      conditions.forEach((con, i) => {
        let path = con.modulePath;
        let split = path.split("/");
        let ext = split[0];
        let script = split[split.length - 1].replace(".js", "");
        let settings = con.settings;
        let type = !con.negate ? "Condition" : "Exception";
        chipData.push({
          ext,
          script,
          settings,
          icon: "pi pi-question-circle",
          type,
          key: "condition-" + i,
          severity: con.negate ? "danger" : "success",
          bclass: con.negate ? "p-button-danger" : "p-button-success"
        });
      });
      actions.forEach((act, i) => {
        let path = act.modulePath;
        let split = path.split("/");
        let ext = split[0];
        let script = split[split.length - 1].replace(".js", "");
        let settings = act.settings;
        if (path.indexOf("customCode.js") > -1) {
          if (settings.language == "javascript") {
            script = script + " (Javascript)";
          } else {
            script = script + " (HTML)";
          }
        }
        chipData.push({
          ext,
          script,
          settings,
          icon: "pi pi-tags",
          type: "Action",
          key: "action-" + i,
          severity: "primary",
          bclass: "p-button-help actionButton"
        });
      });
      return chipData;
    }
  }
};
</script>
<style scoped>
.actionButton {
  background: #ce93d8;
}
</style>
